<template>
  <div class="signup">
    <div class="bg-primary mb-5">
      <div class="container">
        <b-row align-v="center" align-h="center" class="py-5">
          <b-col cols="12" lg="6" class="text-center">
            <h1 class="h2 text-white font-weight-bolder mb-2">
              Join Our Affiliate Program
            </h1>
            <p class="text-white mb-0 text-small font-weight-light">
              <small> Succeed with your business today!</small>
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="container">
      <b-row align-v="center" align-h="center">
        <b-col cols="12" lg="8" class="text-center">
          <div v-if="loading" class="text-center my-5 py-5">
            <b-spinner label="Loading..." variant="primary"></b-spinner>
          </div>
          <div id="hubspotForm"></div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignupPage',
  metaInfo() {
    return {
      title: 'Sign up to be a SmartCredit Affiliate',
      link: [
        {
          rel: 'canonical',
          href: 'https://www.smartcreditaffiliates.com/signup',
        },
      ],
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    loadHubspotForm() {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = '//js.hsforms.net/forms/embed/v2.js'
      document.body.appendChild(script)

      script.addEventListener('load', () => {
        if (window.hbspt) {
          this.loading = false
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '9409493',
            formId: '3d15d8ed-b94f-427e-82d6-68a3de5d9ca6',
            target: '#hubspotForm',
          })
        }
      })
    },
  },
  mounted: function() {
    this.loadHubspotForm()
    return document.dispatchEvent(new Event('x-app-rendered'))
  },
}
</script>
