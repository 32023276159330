<template>
  <div class="home">
    <div class="bg-warning mb-5">
      <div class="container">
        <b-row align-v="center" class="py-5">
          <b-col cols="12" lg="7" xl="6" order="2" order-lg="1">
            <h1 class="text-white font-weight-bolder mb-5 d-none d-lg-block">
              Join Our Affiliate or <br />Co-Brand Today!
            </h1>
            <ul
              class="list-unstyled font-weight-bold text-white row mt-3 px-5 px-lg-0"
            >
              <li class="col-12 col-md-6 mb-2">
                <ArrowCircle style="width:20px; vertical-align: sub;" /> Get a
                Better Product
              </li>
              <li class="col-12 col-md-6 mb-2">
                <ArrowCircle style="width:20px; vertical-align: sub;" /> Make
                More Money
              </li>
              <li class="col-12 col-md-6 mb-2">
                <ArrowCircle style="width:20px; vertical-align: sub;" /> Easy
                Integration
              </li>
              <li class="col-12 col-md-6 mb-2">
                <ArrowCircle style="width:20px; vertical-align: sub;" />
                Powerful Data &amp; Reports
              </li>
            </ul>
            <div class="text-center text-lg-left mb-5 mb-lg-0">
              <b-button
                :to="{ name: 'SignupPage' }"
                pill
                size="large"
                variant="secondary"
                class="px-4 mt-2 mt-lg-4"
                >Get Started Now</b-button
              >
            </div>
          </b-col>
          <b-col
            cols="12"
            lg="5"
            xl="6"
            order="1"
            order-lg="2"
            class="text-center"
          >
            <p class="h1 text-white font-weight-bolder mt-5 d-lg-none">
              Join Our Affiliate or <br />Co-Brand Today!
            </p>
            <b-img
              fluid
              src="@/assets/images/homehero-memberdashboard.png"
              alt="SmartCredit® Member Dashboard"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="container">
      <b-row class="mb-3 mb-lg-5 pb-3 pb-lg-5">
        <b-col class="text-center">
          <b-img
            fluid
            src="@/assets/images/home_improvingscore.png"
            alt="Our platform. Your expertise. Together we're changing lives."
            class="mb-lg-4 pb-3"
          />
          <h2 class="h5 font-weight-semibold platform-caption">
            Our platform. Your expertise. Together we’re changing lives.
          </h2>
        </b-col>
      </b-row>
      <b-row class="py-3 py-lg-5">
        <b-col class="text-center">
          <h3 class="h2 font-weight-bolder mb-3">A Better Product</h3>
          <h4 class="h4 font-weight-normal">
            To Get More Customers &amp; Keep More Customers
          </h4>
        </b-col>
      </b-row>
      <b-row class="mb-3 mb-lg-5 pb-3 pb-lg-5 slider">
        <b-col class="pr-4 d-flex flex-column slide active-right" ref="slide-1">
          <div class="bg-primary">
            <b-img
              fluid
              src="@/assets/images/home_scoremastercard.png"
              alt="ScoreMaster"
            />
          </div>
          <div class="shadow-sm p-4 text-left flex-grow-1">
            <p class="h5 font-weight-bolder text-center mb-3">
              ScoreMaster<sup>&reg;</sup>
            </p>
            <p>
              This tool allows your consumers to plan their future credit score
              based on spending or payments.
            </p>
            <p>
              Identifies specific accounts, amounts, and timing for payments and
              spending to maximize a credit score.
            </p>
          </div>
        </b-col>
        <b-col class="px-4 d-flex flex-column slide" ref="slide-2">
          <div class="bg-warning">
            <b-img
              fluid
              src="@/assets/images/home_scorebuildercard.png"
              alt="ScoreBuilder"
            />
          </div>
          <div class="shadow-sm p-4 text-left flex-grow-1">
            <p class="h5 font-weight-bolder text-center mb-3">
              ScoreBuilder<sup>&reg;</sup>
            </p>
            <p>
              Your consumers get a unique plan Action Buttons and education
              toward a better credit score.
            </p>
            <p>
              Patented Action Buttons allow your consumers to quickly ask
              questions or resolve problems directly with the source.
            </p>
          </div></b-col
        >
        <b-col class="pl-4 d-flex flex-column slide" ref="slide-3">
          <div class="bg-info">
            <b-img
              fluid
              src="@/assets/images/home_scoretrackercard.png"
              alt="ScoreTracker"
            />
          </div>
          <div class="shadow-sm p-4 text-left flex-grow-1">
            <p class="h5 font-weight-bolder text-center mb-3">
              ScoreTracker
            </p>
            <p>
              Your consumers will appreciate seeing their different types of
              credit scores and how they're progressing in an easy to understand
              way.
            </p>
            <p>
              Includes simple SMS and Email alerts of key changes to a credit
              report and score.
            </p>
          </div></b-col
        >
        <div class="slider-nav d-lg-none">
          <div @click="slideChange()" class="slider-button previous">
            <CaretLeft />
          </div>
          <div @click="slideChange('next')" class="slider-button next">
            <CaretLeft class="rotate-180" />
          </div>
        </div>
      </b-row>
      <b-row class="mb-3 mb-lg-5 py-3 py-lg-5">
        <b-col class="text-center">
          <h3 class="h2 font-weight-bolder mb-5">
            With 3B Reports &amp; Scores
          </h3>
          <b-row align-v="center" align-h="center" class="mb-5">
            <b-col sm
              ><b-img
                fluid
                src="@/assets/images/transunion.png"
                alt="3 Bureau Credit Report with Experian®, Equifax®, and TransUnion®"
                class="bureau-logo"
            /></b-col>
            <b-col sm
              ><b-img
                fluid
                src="@/assets/images/equifax.png"
                alt="3 Bureau Credit Report with Experian®, Equifax®, and TransUnion®"
                class="bureau-logo"
            /></b-col>
            <b-col sm
              ><b-img
                fluid
                src="@/assets/images/experian.png"
                alt="3 Bureau Credit Report with Experian®, Equifax®, and TransUnion®"
                class="bureau-logo"
            /></b-col>
          </b-row>
          <b-button
            :to="{ name: 'SignupPage' }"
            pill
            size="large"
            variant="secondary"
            class="px-4"
            >Get Started Now</b-button
          >
        </b-col>
      </b-row>
      <b-row class="py-5 mb-5">
        <b-col class="text-center">
          <h3 class="h2 font-weight-bolder mb-3">Make More Money</h3>
          <b-row align-h="center" class="mb-5">
            <b-col lg="5" class="d-flex align-items-center">
              <ul class="text-left mr-auto pr-3">
                <li class="mb-2">Bigger Payouts</li>
                <li class="mb-2">Flexible Retail Pricing</li>
                <li class="mb-2">The Best LTV</li>
                <li class="mb-2">More Revenue Verticals</li>
              </ul>
              <b-img
                fluid
                src="@/assets/images/home_dollarsign.png"
                alt="Make More Money"
                class="flush-right"
              />
            </b-col>
          </b-row>
          <b-button
            :to="{ name: 'SignupPage' }"
            pill
            size="large"
            variant="secondary"
            class="px-4"
            >Get Started Now</b-button
          >
        </b-col>
      </b-row>
      <b-row class="mb-3 mb-lg-5 py-3 py-lg-5">
        <b-col class="text-center">
          <h3 class="h2 font-weight-bolder mb-5">An Easy Integration</h3>
          <b-row align-h="center" class="mb-5">
            <b-col lg="5" class="text-left">
              <p class="font-weight-bold h5 mb-2">Partner Portal</p>
              <p class="mb-4">
                Manage your customers account through our proprietary portal.
              </p>
              <p class="font-weight-bold h5 mb-2">Reporting</p>
              <p class="mb-4">
                Gain insight in your customer base with detailed reports + API
                access.
              </p>
              <p class="font-weight-bold h5 mb-2">Co-Branding</p>
              <p class="mb-4">
                Choose your logos, pricing &amp; marketing message.
              </p>
              <p class="font-weight-bold h5 mb-2">API Enrollment</p>
              <p class="mb-0">Create custom sign-up pages.</p>
            </b-col>
          </b-row>
          <b-button
            :to="{ name: 'SignupPage' }"
            pill
            size="large"
            variant="secondary"
            class="px-4"
            >Get Started Now</b-button
          >
        </b-col>
      </b-row>
      <b-row class="mb-3 mb-lg-5 py-3 py-lg-5">
        <b-col class="text-center">
          <h3 class="h2 font-weight-bolder mb-5">
            See What Affiliates Are Saying
          </h3>
          <b-row align-h="center">
            <b-col sm="auto" class="mb-5 mb-lg-0">
              <iframe
                class="youtube-player"
                :src="getYoutubeEmbedUrl(testimonial2VideoId)"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                width="240"
                height="429"
              ></iframe>
            </b-col>
            <b-col cols="auto" class="mb-5">
              <iframe
                class="youtube-player"
                :src="getYoutubeEmbedUrl(testimonial1VideoId)"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                width="240"
                height="429"
              ></iframe>
            </b-col>
          </b-row>
          <b-row align-h="center">
            <b-col cols="auto">
              <iframe
                class="youtube-player"
                :src="getVimeoEmbedUrl(testimonial3VideoId)"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                width="540"
                height="304"
              ></iframe>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ArrowCircle from '@/assets/icons/arrow_circle.svg'
import CaretLeft from '@/assets/icons/caret_left.svg'

export default {
  name: 'Home',
  metaInfo() {
    return {
      title: 'Become a SmartCredit Affiliate and start making money now!',
      link: [
        {
          rel: 'canonical',
          href: 'https://www.smartcreditaffiliates.com/',
        },
      ],
    }
  },
  components: {
    ArrowCircle,
    CaretLeft,
  },
  data() {
    return {
      activeSlide: 1,
      playVideo: null,
      testimonial1VideoId: '3tS_ZXDKyeg',
      testimonial2VideoId: 'LphgKbC1gRA',
      testimonial3VideoId: '458382564',
    }
  },
  methods: {
    slideChange(direction = 'previous') {
      let classname = 'active-right'

      this.$refs[`slide-${this.activeSlide.toString()}`].classList.remove(
        'active-right',
        'active-left'
      )

      if (direction === 'previous') {
        classname = 'active-left'
        this.activeSlide = this.activeSlide === 1 ? 3 : this.activeSlide - 1
      } else {
        this.activeSlide = this.activeSlide === 3 ? 1 : this.activeSlide + 1
      }

      this.$refs[`slide-${this.activeSlide.toString()}`].classList.add(
        classname
      )
    },
    getYoutubeEmbedUrl(videoId) {
      return `https://www.youtube.com/embed/${videoId}?showinfo=0`
    },
    getVimeoEmbedUrl(videoId) {
      return `https://player.vimeo.com/video/${videoId}`
    },
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>
