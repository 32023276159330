<template>
  <footer class="bg-gray-500">
    <div class="bg-gray-200 py-5">
      <div class="container">
        <b-row>
          <b-col class="d-none d-lg-block"
            ><b-link :to="{ name: 'SignupPage' }">Sign Up Now</b-link></b-col
          >
          <b-col lg="5">
            <p class="mb-2">Phone: 1 (860) 858-4900</p>
            <p class="mb-5 mb-lg-0">Available: Mon-Fri 9:00AM to 5:00PM EST</p>
          </b-col>
          <b-col class="d-lg-flex text-center">
            <b-link
              href="https://facebook.com/creditadmiral"
              class="ml-lg-auto"
              target="blank"
            >
              <FacebookIcon class="social-icon" />
            </b-link>
            <b-link
              href="https://twitter.com/creditadmiral"
              class="ml-2"
              target="blank"
            >
              <TwitterIcon class="social-icon" />
            </b-link>
            <b-link
              href="https://www.linkedin.com/company/credit-admiral-software"
              class="ml-2"
              target="blank"
            >
              <LinkedinIcon class="social-icon" />
            </b-link>
            <b-link
              href="https://instagram.com/creditadmiral"
              class="ml-2"
              target="blank"
            >
              <InstagramIcon class="social-icon" />
            </b-link>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="py-5">
      <div class="container">
        <b-row>
          <b-col>
            <p class="mb-2 text-white">
              |
              <b-link :to="{ name: 'PrivacyPage' }" class="text-white">
                <small>Privacy Policy</small></b-link
              >
              |
            </p>
            <p class="mb-0">
              <small class="text-gray-400"
                >&copy;{{ currYear }} smartcreditaffiliates.com<sup
                  >&reg;</sup
                ></small
              >
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </footer>
</template>

<script>
import FacebookIcon from '@/assets/icons/facebook_icon.svg'
import TwitterIcon from '@/assets/icons/twitter_icon.svg'
import LinkedinIcon from '@/assets/icons/linkedin_icon.svg'
import InstagramIcon from '@/assets/icons/instagram_icon.svg'

export default {
  name: 'Footer',
  components: { FacebookIcon, TwitterIcon, LinkedinIcon, InstagramIcon },
  data() {
    return {
      currYear: new Date().getFullYear(),
    }
  },
}
</script>
