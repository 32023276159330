<template>
  <div class="logo">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 437.221 28.08">
      <g id="Group_194" data-name="Group 194" transform="translate(-26 -36)">
        <g
          id="Group_2"
          data-name="Group 2"
          transform="translate(1570.101 2178.088)"
        >
          <g id="R" transform="translate(231)" style="isolation: isolate">
            <g
              id="Group_195"
              data-name="Group 195"
              transform="translate(-1969.015 -2614.843)"
            >
              <path
                id="Path_557"
                data-name="Path 557"
                d="M623.5,479.334h2.241a3.964,3.964,0,0,1,.838.086,2,2,0,0,1,.7.285,1.43,1.43,0,0,1,.475.538,1.815,1.815,0,0,1,.176.843,1.688,1.688,0,0,1-.319,1.039,1.437,1.437,0,0,1-.932.542l1.472,2.436h-1.529L625.41,482.8h-.63V485.1H623.5Zm1.276,2.387h.753c.114,0,.236,0,.365-.012a1.135,1.135,0,0,0,.347-.073.6.6,0,0,0,.258-.191.585.585,0,0,0,.1-.367.6.6,0,0,0-.091-.35.589.589,0,0,0-.229-.2,1.034,1.034,0,0,0-.318-.094,2.617,2.617,0,0,0-.352-.024h-.835Z"
                transform="translate(0 0)"
                fill="#2863c5"
              />
            </g>
          </g>
          <path
            id="Path_1"
            data-name="Path 1"
            d="M-1343.572-2127.027a5.7,5.7,0,0,1-5.693-5.693,5.7,5.7,0,0,1,5.693-5.693,5.7,5.7,0,0,1,5.693,5.693A5.7,5.7,0,0,1-1343.572-2127.027Zm0-10.717a5.03,5.03,0,0,0-5.025,5.024,5.03,5.03,0,0,0,5.025,5.025,5.03,5.03,0,0,0,5.024-5.025A5.03,5.03,0,0,0-1343.572-2137.744Z"
            transform="translate(231)"
            fill="#2863c5"
          />
        </g>
        <g
          id="Group_196"
          data-name="Group 196"
          transform="translate(-440.961 -333.839)"
        >
          <path
            id="Path_558"
            data-name="Path 558"
            d="M477.906,385.139a3.824,3.824,0,0,0-3.133-1.476,2.939,2.939,0,0,0-1.331.324,1.147,1.147,0,0,0-.649,1.116,1,1,0,0,0,.666.954,8.75,8.75,0,0,0,1.693.54l2.2.5a8.244,8.244,0,0,1,2.2.846,4.927,4.927,0,0,1,1.693,1.566,4.676,4.676,0,0,1,.666,2.646,4.9,4.9,0,0,1-.739,2.79,5.651,5.651,0,0,1-1.89,1.764,8.108,8.108,0,0,1-2.591.936,15.414,15.414,0,0,1-2.844.27,13.752,13.752,0,0,1-3.708-.522,6.913,6.913,0,0,1-3.169-1.854l3.277-3.636a5.429,5.429,0,0,0,1.673,1.422,4.514,4.514,0,0,0,2.143.486,5,5,0,0,0,1.691-.27,1,1,0,0,0,.756-.99,1.1,1.1,0,0,0-.666-1.026,7.323,7.323,0,0,0-1.692-.576q-1.026-.234-2.2-.5a9.065,9.065,0,0,1-2.2-.81,4.6,4.6,0,0,1-1.692-1.53,4.669,4.669,0,0,1-.666-2.646,5.337,5.337,0,0,1,.63-2.664,5.546,5.546,0,0,1,1.657-1.836,7.18,7.18,0,0,1,2.357-1.062,10.817,10.817,0,0,1,2.7-.342,12.654,12.654,0,0,1,3.491.5,6.493,6.493,0,0,1,2.989,1.8Z"
            fill="#2863c5"
          />
          <path
            id="Path_559"
            data-name="Path 559"
            d="M484.674,379.991h5.183v2.376h.073a4.361,4.361,0,0,1,.719-1.008,5.267,5.267,0,0,1,1.116-.9,6.388,6.388,0,0,1,1.477-.648,6.145,6.145,0,0,1,1.8-.252,6.841,6.841,0,0,1,3.259.756,4.789,4.789,0,0,1,2.141,2.376,5.844,5.844,0,0,1,2.268-2.412,6.817,6.817,0,0,1,3.277-.72,6.681,6.681,0,0,1,2.969.594,4.869,4.869,0,0,1,1.909,1.62,6.858,6.858,0,0,1,1.007,2.412,13.763,13.763,0,0,1,.307,2.97v10.332h-5.4V387.3a4.077,4.077,0,0,0-.522-2.106,1.975,1.975,0,0,0-1.854-.882,3.573,3.573,0,0,0-1.567.306,2.569,2.569,0,0,0-1.007.846,3.527,3.527,0,0,0-.541,1.26,6.939,6.939,0,0,0-.162,1.512v9.252h-5.4v-9.252c0-.311-.012-.7-.036-1.152a4.607,4.607,0,0,0-.252-1.3,2.487,2.487,0,0,0-.7-1.044,2.082,2.082,0,0,0-1.423-.432,3.336,3.336,0,0,0-1.692.378,2.574,2.574,0,0,0-.989,1.008,4.092,4.092,0,0,0-.45,1.422,12.251,12.251,0,0,0-.108,1.656v8.712h-5.4Z"
            fill="#2863c5"
          />
          <path
            id="Path_560"
            data-name="Path 560"
            d="M525.974,395.291H525.9a5.078,5.078,0,0,1-2.394,2.016,8.23,8.23,0,0,1-3.15.612,8.122,8.122,0,0,1-2.358-.342,5.952,5.952,0,0,1-2-1.026,4.945,4.945,0,0,1-1.368-1.692,5.165,5.165,0,0,1-.5-2.34,5.368,5.368,0,0,1,.558-2.556,4.994,4.994,0,0,1,1.512-1.728,7.534,7.534,0,0,1,2.178-1.062,16.007,16.007,0,0,1,2.538-.558,24.505,24.505,0,0,1,2.628-.216q1.314-.036,2.431-.036a2.812,2.812,0,0,0-1.027-2.286,3.7,3.7,0,0,0-2.43-.846,5.286,5.286,0,0,0-2.43.558,6.667,6.667,0,0,0-1.962,1.53l-2.88-2.952a9.91,9.91,0,0,1,3.528-2.106,12.609,12.609,0,0,1,4.176-.7,10.913,10.913,0,0,1,3.906.594A5.68,5.68,0,0,1,529.3,381.9a6.728,6.728,0,0,1,1.278,2.826,18.553,18.553,0,0,1,.36,3.87v8.892h-4.967Zm-1.333-5.472c-.408,0-.917.018-1.53.054a7.791,7.791,0,0,0-1.763.306,3.952,3.952,0,0,0-1.441.756,1.743,1.743,0,0,0-.594,1.4,1.544,1.544,0,0,0,.828,1.44,3.464,3.464,0,0,0,1.729.468,5.418,5.418,0,0,0,1.529-.216,4.538,4.538,0,0,0,1.314-.612,2.949,2.949,0,0,0,.918-1.008,2.9,2.9,0,0,0,.343-1.44v-1.152Z"
            fill="#2863c5"
          />
          <path
            id="Path_561"
            data-name="Path 561"
            d="M534.325,379.991h5.4V382.8h.072a6.637,6.637,0,0,1,2.052-2.43,5.187,5.187,0,0,1,2.988-.81c.311,0,.624.013.936.036a4.746,4.746,0,0,1,.864.144v4.932a8.158,8.158,0,0,0-1.134-.27,7.462,7.462,0,0,0-1.17-.09,5.734,5.734,0,0,0-2.448.432,3.256,3.256,0,0,0-1.386,1.206,4.588,4.588,0,0,0-.63,1.854,18.051,18.051,0,0,0-.144,2.376v7.308h-5.4Z"
            fill="#2863c5"
          />
          <path
            id="Path_562"
            data-name="Path 562"
            d="M562.893,384.311H558.14v5.832a10.759,10.759,0,0,0,.073,1.314,2.664,2.664,0,0,0,.323,1.026,1.634,1.634,0,0,0,.775.666,3.412,3.412,0,0,0,1.386.234,9.335,9.335,0,0,0,1.134-.09,1.914,1.914,0,0,0,1.062-.414v4.5a8.182,8.182,0,0,1-1.872.432,17.274,17.274,0,0,1-1.908.108,10.38,10.38,0,0,1-2.52-.288,5.748,5.748,0,0,1-2.016-.918,4.3,4.3,0,0,1-1.35-1.638,5.6,5.6,0,0,1-.486-2.448v-8.316h-3.456v-4.32h3.456v-5.184h5.4v5.184h4.753Z"
            fill="#2863c5"
          />
          <path
            id="Path_563"
            data-name="Path 563"
            d="M577.473,385.643a3.052,3.052,0,0,0-1.116-.954,3.288,3.288,0,0,0-1.549-.378,4.134,4.134,0,0,0-3.186,1.224,5.1,5.1,0,0,0,0,6.408,4.131,4.131,0,0,0,3.186,1.224,2.906,2.906,0,0,0,1.549-.4,4.74,4.74,0,0,0,1.116-.936l3.563,3.744a6.879,6.879,0,0,1-2.969,1.818,11.167,11.167,0,0,1-3.259.522,11.022,11.022,0,0,1-3.8-.648,9.13,9.13,0,0,1-3.1-1.854,8.7,8.7,0,0,1-2.069-2.9,9.828,9.828,0,0,1,0-7.56,8.709,8.709,0,0,1,2.069-2.9,9.146,9.146,0,0,1,3.1-1.854,11.022,11.022,0,0,1,3.8-.648,11.2,11.2,0,0,1,3.259.522,6.9,6.9,0,0,1,2.969,1.818Z"
            fill="#2863c5"
          />
          <path
            id="Path_564"
            data-name="Path 564"
            d="M583.981,379.991h5.4V382.8h.072a6.637,6.637,0,0,1,2.052-2.43,5.185,5.185,0,0,1,2.988-.81c.311,0,.624.013.936.036a4.746,4.746,0,0,1,.864.144v4.932a8.158,8.158,0,0,0-1.134-.27,7.481,7.481,0,0,0-1.17-.09,5.739,5.739,0,0,0-2.449.432,3.248,3.248,0,0,0-1.385,1.206,4.558,4.558,0,0,0-.63,1.854,18.051,18.051,0,0,0-.144,2.376v7.308h-5.4Z"
            fill="#2863c5"
          />
          <path
            id="Path_565"
            data-name="Path 565"
            d="M614.544,394.463a8.6,8.6,0,0,1-3.276,2.556,9.814,9.814,0,0,1-4.1.9,11.022,11.022,0,0,1-3.8-.648,9.13,9.13,0,0,1-3.1-1.854,8.684,8.684,0,0,1-2.069-2.9,9.828,9.828,0,0,1,0-7.56,8.694,8.694,0,0,1,2.069-2.9,9.146,9.146,0,0,1,3.1-1.854,11.022,11.022,0,0,1,3.8-.648,8.648,8.648,0,0,1,3.4.648,7.092,7.092,0,0,1,2.592,1.854,8.485,8.485,0,0,1,1.638,2.9,11.717,11.717,0,0,1,.575,3.78v1.692H602.845a4.273,4.273,0,0,0,1.4,2.466,3.974,3.974,0,0,0,2.664.918,4.054,4.054,0,0,0,2.25-.594,6.038,6.038,0,0,0,1.6-1.53Zm-4.571-7.7a3.135,3.135,0,0,0-.9-2.34,3.2,3.2,0,0,0-2.412-.972,4.048,4.048,0,0,0-1.584.288,3.965,3.965,0,0,0-1.17.738,3.206,3.206,0,0,0-.756,1.044,3.417,3.417,0,0,0-.306,1.242Z"
            fill="#2863c5"
          />
          <path
            id="Path_566"
            data-name="Path 566"
            d="M636.008,397.487H631.04v-2.3h-.072a5.927,5.927,0,0,1-.846.954,6.4,6.4,0,0,1-1.224.882,7.439,7.439,0,0,1-1.548.648,6.083,6.083,0,0,1-1.746.252,9.018,9.018,0,0,1-3.528-.666,7.868,7.868,0,0,1-2.718-1.872,8.339,8.339,0,0,1-1.746-2.862,10.411,10.411,0,0,1-.612-3.636,11.026,11.026,0,0,1,.558-3.51,9.659,9.659,0,0,1,1.584-2.97,7.75,7.75,0,0,1,2.5-2.07,7.1,7.1,0,0,1,3.348-.774,9.3,9.3,0,0,1,3.15.522,5.052,5.052,0,0,1,2.394,1.782h.072V370.271h5.4Zm-4.968-8.748a4.529,4.529,0,0,0-1.134-3.2,4.759,4.759,0,0,0-6.372,0,5.093,5.093,0,0,0,0,6.408,4.759,4.759,0,0,0,6.372,0A4.527,4.527,0,0,0,631.04,388.739Z"
            fill="#2863c5"
          />
          <path
            id="Path_567"
            data-name="Path 567"
            d="M639.968,374.483a3.134,3.134,0,1,1,.918,2.214A3.017,3.017,0,0,1,639.968,374.483Zm.432,5.508h5.4v17.5h-5.4Z"
            fill="#2863c5"
          />
          <path
            id="Path_568"
            data-name="Path 568"
            d="M662.6,384.311h-4.752v5.832a10.759,10.759,0,0,0,.073,1.314,2.646,2.646,0,0,0,.323,1.026,1.634,1.634,0,0,0,.775.666,3.412,3.412,0,0,0,1.386.234,9.335,9.335,0,0,0,1.134-.09,1.911,1.911,0,0,0,1.061-.414v4.5a8.149,8.149,0,0,1-1.871.432,17.274,17.274,0,0,1-1.908.108,10.375,10.375,0,0,1-2.52-.288,5.762,5.762,0,0,1-2.017-.918,4.307,4.307,0,0,1-1.349-1.638,5.6,5.6,0,0,1-.486-2.448v-8.316h-3.457v-4.32h3.457v-5.184h5.4v5.184H662.6Z"
            fill="#2863c5"
          />
          <path
            id="Path_569"
            data-name="Path 569"
            d="M677.319,395.291h-.071a5.086,5.086,0,0,1-2.395,2.016,8.228,8.228,0,0,1-3.149.612,8.117,8.117,0,0,1-2.358-.342,5.932,5.932,0,0,1-2-1.026,4.936,4.936,0,0,1-1.369-1.692,5.165,5.165,0,0,1-.5-2.34,5.368,5.368,0,0,1,.558-2.556,4.986,4.986,0,0,1,1.513-1.728,7.521,7.521,0,0,1,2.178-1.062,15.983,15.983,0,0,1,2.538-.558,24.5,24.5,0,0,1,2.628-.216q1.313-.036,2.429-.036a2.813,2.813,0,0,0-1.025-2.286,3.708,3.708,0,0,0-2.431-.846,5.285,5.285,0,0,0-2.43.558,6.65,6.65,0,0,0-1.961,1.53l-2.88-2.952a9.893,9.893,0,0,1,3.528-2.106,12.6,12.6,0,0,1,4.176-.7,10.907,10.907,0,0,1,3.9.594,5.668,5.668,0,0,1,2.448,1.746,6.728,6.728,0,0,1,1.279,2.826,18.619,18.619,0,0,1,.36,3.87v8.892h-4.969Zm-1.332-5.472q-.612,0-1.529.054a7.818,7.818,0,0,0-1.765.306,3.951,3.951,0,0,0-1.439.756,1.741,1.741,0,0,0-.6,1.4,1.544,1.544,0,0,0,.828,1.44,3.464,3.464,0,0,0,1.729.468,5.424,5.424,0,0,0,1.53-.216,4.531,4.531,0,0,0,1.313-.612,2.949,2.949,0,0,0,.918-1.008,2.9,2.9,0,0,0,.342-1.44v-1.152Z"
            fill="#2863c5"
          />
          <path
            id="Path_570"
            data-name="Path 570"
            d="M687.544,384.311h-3.565v-4.32h3.565v-3.1a14.034,14.034,0,0,1,.252-2.718,5.509,5.509,0,0,1,.971-2.25,4.757,4.757,0,0,1,2.071-1.53,9.417,9.417,0,0,1,3.546-.558q.827,0,1.62.072a10.983,10.983,0,0,1,1.548.252l-.252,4.572a6.552,6.552,0,0,0-.865-.27,4.138,4.138,0,0,0-.9-.09,2.912,2.912,0,0,0-1.944.558,3,3,0,0,0-.649,2.286v2.772h4v4.32h-4v13.176h-5.4Z"
            fill="#2863c5"
          />
          <path
            id="Path_571"
            data-name="Path 571"
            d="M702.863,384.311H699.3v-4.32h3.564v-3.1a14.039,14.039,0,0,1,.252-2.718,5.5,5.5,0,0,1,.972-2.25,4.754,4.754,0,0,1,2.07-1.53,9.421,9.421,0,0,1,3.546-.558q.828,0,1.62.072a10.982,10.982,0,0,1,1.548.252l-.252,4.572a6.541,6.541,0,0,0-.864-.27,4.154,4.154,0,0,0-.9-.09,2.913,2.913,0,0,0-1.944.558,3,3,0,0,0-.648,2.286v2.772h4v4.32h-4v13.176h-5.4Zm12.088-9.828a3.134,3.134,0,1,1,.918,2.214A3.017,3.017,0,0,1,714.951,374.483Zm.432,5.508h5.4v17.5h-5.4Z"
            fill="#2863c5"
          />
          <path
            id="Path_572"
            data-name="Path 572"
            d="M724.743,370.271h5.4v27.216h-5.4Z"
            fill="#2863c5"
          />
          <path
            id="Path_573"
            data-name="Path 573"
            d="M733.671,374.483a3.134,3.134,0,1,1,.918,2.214A3.013,3.013,0,0,1,733.671,374.483Zm.432,5.508h5.4v17.5h-5.4Z"
            fill="#2863c5"
          />
          <path
            id="Path_574"
            data-name="Path 574"
            d="M754.37,395.291H754.3a5.085,5.085,0,0,1-2.4,2.016,8.228,8.228,0,0,1-3.149.612,8.124,8.124,0,0,1-2.359-.342,5.932,5.932,0,0,1-2-1.026,4.945,4.945,0,0,1-1.368-1.692,5.164,5.164,0,0,1-.5-2.34,5.368,5.368,0,0,1,.558-2.556,4.987,4.987,0,0,1,1.513-1.728,7.516,7.516,0,0,1,2.177-1.062,16.008,16.008,0,0,1,2.538-.558,24.5,24.5,0,0,1,2.628-.216q1.314-.036,2.43-.036a2.813,2.813,0,0,0-1.025-2.286,3.71,3.71,0,0,0-2.431-.846,5.285,5.285,0,0,0-2.43.558,6.667,6.667,0,0,0-1.962,1.53l-2.88-2.952a9.9,9.9,0,0,1,3.529-2.106,12.6,12.6,0,0,1,4.176-.7,10.911,10.911,0,0,1,3.905.594A5.675,5.675,0,0,1,757.7,381.9a6.729,6.729,0,0,1,1.278,2.826,18.549,18.549,0,0,1,.361,3.87v8.892H754.37Zm-1.332-5.472q-.612,0-1.529.054a7.8,7.8,0,0,0-1.765.306,3.952,3.952,0,0,0-1.439.756,1.741,1.741,0,0,0-.595,1.4,1.544,1.544,0,0,0,.828,1.44,3.464,3.464,0,0,0,1.728.468,5.426,5.426,0,0,0,1.531-.216,4.543,4.543,0,0,0,1.313-.612,2.949,2.949,0,0,0,.918-1.008,2.905,2.905,0,0,0,.342-1.44v-1.152Z"
            fill="#2863c5"
          />
          <path
            id="Path_575"
            data-name="Path 575"
            d="M774.639,384.311h-4.752v5.832a10.982,10.982,0,0,0,.071,1.314,2.648,2.648,0,0,0,.324,1.026,1.63,1.63,0,0,0,.775.666,3.406,3.406,0,0,0,1.385.234,9.335,9.335,0,0,0,1.134-.09,1.918,1.918,0,0,0,1.063-.414v4.5a8.189,8.189,0,0,1-1.873.432,17.274,17.274,0,0,1-1.908.108,10.366,10.366,0,0,1-2.519-.288,5.745,5.745,0,0,1-2.017-.918,4.3,4.3,0,0,1-1.349-1.638,5.582,5.582,0,0,1-.487-2.448v-8.316H761.03v-4.32h3.456v-5.184h5.4v5.184h4.752Z"
            fill="#2863c5"
          />
          <path
            id="Path_576"
            data-name="Path 576"
            d="M793.933,394.463a8.612,8.612,0,0,1-3.275,2.556,9.814,9.814,0,0,1-4.1.9,11.019,11.019,0,0,1-3.8-.648,9.107,9.107,0,0,1-3.1-1.854,8.687,8.687,0,0,1-2.07-2.9,9.827,9.827,0,0,1,0-7.56,8.7,8.7,0,0,1,2.07-2.9,9.123,9.123,0,0,1,3.1-1.854,11.019,11.019,0,0,1,3.8-.648,8.643,8.643,0,0,1,3.4.648,7.091,7.091,0,0,1,2.592,1.854,8.434,8.434,0,0,1,1.637,2.9,11.689,11.689,0,0,1,.577,3.78v1.692H782.233a4.281,4.281,0,0,0,1.405,2.466,3.968,3.968,0,0,0,2.664.918,4.061,4.061,0,0,0,2.25-.594,6.048,6.048,0,0,0,1.6-1.53Zm-4.571-7.7a3.127,3.127,0,0,0-.9-2.34,3.2,3.2,0,0,0-2.412-.972,4.052,4.052,0,0,0-1.584.288,3.991,3.991,0,0,0-1.17.738,3.235,3.235,0,0,0-.756,1.044,3.439,3.439,0,0,0-.307,1.242Z"
            fill="#2863c5"
          />
          <path
            id="Path_577"
            data-name="Path 577"
            d="M807.937,385.139a3.823,3.823,0,0,0-3.131-1.476,2.943,2.943,0,0,0-1.332.324,1.146,1.146,0,0,0-.648,1.116,1,1,0,0,0,.666.954,8.726,8.726,0,0,0,1.691.54l2.2.5a8.277,8.277,0,0,1,2.2.846,4.912,4.912,0,0,1,1.691,1.566,4.668,4.668,0,0,1,.666,2.646,4.905,4.905,0,0,1-.737,2.79,5.66,5.66,0,0,1-1.89,1.764,8.114,8.114,0,0,1-2.592.936,15.4,15.4,0,0,1-2.844.27,13.747,13.747,0,0,1-3.708-.522,6.9,6.9,0,0,1-3.168-1.854l3.275-3.636a5.424,5.424,0,0,0,1.675,1.422,4.51,4.51,0,0,0,2.142.486,5.01,5.01,0,0,0,1.692-.27,1,1,0,0,0,.756-.99,1.1,1.1,0,0,0-.666-1.026,7.339,7.339,0,0,0-1.692-.576l-2.2-.5a9.066,9.066,0,0,1-2.2-.81,4.588,4.588,0,0,1-1.692-1.53,4.661,4.661,0,0,1-.666-2.646,5.337,5.337,0,0,1,.63-2.664,5.543,5.543,0,0,1,1.656-1.836,7.176,7.176,0,0,1,2.358-1.062,10.8,10.8,0,0,1,2.7-.342,12.668,12.668,0,0,1,3.493.5,6.5,6.5,0,0,1,2.988,1.8Z"
            fill="#2863c5"
          />
          <path
            id="Path_578"
            data-name="Path 578"
            d="M814.813,394.391a3.373,3.373,0,0,1,.252-1.3,3.181,3.181,0,0,1,1.765-1.764,3.459,3.459,0,0,1,2.592,0,3.186,3.186,0,0,1,1.763,1.764,3.458,3.458,0,0,1,0,2.592,3.186,3.186,0,0,1-1.763,1.764,3.458,3.458,0,0,1-2.592,0,3.181,3.181,0,0,1-1.765-1.764A3.366,3.366,0,0,1,814.813,394.391Z"
            fill="#2863c5"
          />
          <path
            id="Path_579"
            data-name="Path 579"
            d="M836.205,385.643a3.044,3.044,0,0,0-1.116-.954,3.284,3.284,0,0,0-1.548-.378,4.131,4.131,0,0,0-3.186,1.224,5.093,5.093,0,0,0,0,6.408,4.128,4.128,0,0,0,3.186,1.224,2.9,2.9,0,0,0,1.548-.4,4.74,4.74,0,0,0,1.116-.936l3.564,3.744a6.882,6.882,0,0,1-2.97,1.818,11.166,11.166,0,0,1-3.258.522,11.023,11.023,0,0,1-3.8-.648,9.116,9.116,0,0,1-3.1-1.854,8.687,8.687,0,0,1-2.07-2.9,9.827,9.827,0,0,1,0-7.56,8.7,8.7,0,0,1,2.07-2.9,9.132,9.132,0,0,1,3.1-1.854,11.023,11.023,0,0,1,3.8-.648,11.2,11.2,0,0,1,3.258.522,6.9,6.9,0,0,1,2.97,1.818Z"
            fill="#2863c5"
          />
          <path
            id="Path_580"
            data-name="Path 580"
            d="M841.064,388.739a9.172,9.172,0,0,1,.756-3.78,8.725,8.725,0,0,1,2.07-2.9,9.151,9.151,0,0,1,3.1-1.854,11.457,11.457,0,0,1,7.6,0,9.151,9.151,0,0,1,3.1,1.854,8.712,8.712,0,0,1,2.07,2.9,9.828,9.828,0,0,1,0,7.56,8.7,8.7,0,0,1-2.07,2.9,9.136,9.136,0,0,1-3.1,1.854,11.457,11.457,0,0,1-7.6,0,9.135,9.135,0,0,1-3.1-1.854,8.715,8.715,0,0,1-2.07-2.9A9.162,9.162,0,0,1,841.064,388.739Zm5.4,0a4.527,4.527,0,0,0,1.134,3.2,4.759,4.759,0,0,0,6.372,0,5.1,5.1,0,0,0,0-6.408,4.759,4.759,0,0,0-6.372,0A4.529,4.529,0,0,0,846.465,388.739Z"
            fill="#2863c5"
          />
          <path
            id="Path_581"
            data-name="Path 581"
            d="M863.673,379.991h5.183v2.376h.073a4.42,4.42,0,0,1,.719-1.008,5.274,5.274,0,0,1,1.117-.9,6.353,6.353,0,0,1,1.475-.648,6.159,6.159,0,0,1,1.8-.252,6.837,6.837,0,0,1,3.258.756,4.789,4.789,0,0,1,2.141,2.376,5.84,5.84,0,0,1,2.269-2.412,6.812,6.812,0,0,1,3.275-.72,6.691,6.691,0,0,1,2.971.594,4.856,4.856,0,0,1,1.907,1.62,6.84,6.84,0,0,1,1.009,2.412,13.828,13.828,0,0,1,.306,2.97v10.332h-5.4V387.3a4.076,4.076,0,0,0-.521-2.106,1.976,1.976,0,0,0-1.855-.882,3.563,3.563,0,0,0-1.565.306,2.582,2.582,0,0,0-1.009.846,3.506,3.506,0,0,0-.539,1.26,6.879,6.879,0,0,0-.162,1.512v9.252h-5.4v-9.252c0-.311-.012-.7-.036-1.152a4.571,4.571,0,0,0-.252-1.3,2.47,2.47,0,0,0-.7-1.044,2.076,2.076,0,0,0-1.422-.432,3.33,3.33,0,0,0-1.691.378,2.57,2.57,0,0,0-.99,1.008,4.112,4.112,0,0,0-.45,1.422,12.081,12.081,0,0,0-.109,1.656v8.712h-5.4Z"
            fill="#2863c5"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SiteLogo',
  props: {
    loadingText: {
      default: 'Loading',
      type: String,
    },
  },
  data() {
    return {
      logo: process.env.VUE_APP_SITE_LOGO,
    }
  },
}
</script>

<style scoped>
.logo svg {
  max-width: 440px;
}
</style>
