<template>
  <div class="privacy container pb-5">
    <b-row align-h="center">
      <b-col cols="12" lg="10">
        <h1 class="mt-5 mb-4 pt-lg-4 font-weight-bolder">Privacy Policy</h1>
        <p>
          We will never, under any circumstances sell or give your information
          to any third party, PERIOD. Your information is safe with us.
        </p>
        <p>
          Our Privacy Policy is designed to assist you in understanding how we
          collect and use the personal information you provide to us, and to
          assist you in making informed decisions when using our site and our
          products and services.
        </p>
        <p>
          You can opt out simply by sending an email to
          support@smartcreditaffiliates.com.
        </p>
        <p class="font-weight-bolder mb-0">What Information Do We Collect?</p>
        <p>
          When you visit our web site you may provide us with two types of
          information: personal information you knowingly choose to disclose
          that is collected on an individual basis and Web site use information
          collected on an aggregate basis as you and others browse our Web site.
        </p>
        <p class="font-weight-bolder mb-0">
          Personal Information You Choose to Provide Registration Information
        </p>
        <p>
          If you specifically provide us information about yourself (physically
          type your name or email address etc…), your firm or company, and your
          practices when you register to be a member of one of our web pages
          register for certain services, or register for email newsletters and
          alerts. You may also provide additional comments on how you see us
          servicing your needs and interests.
        </p>
        <p class="font-weight-bolder mb-0">Email Information</p>
        <p>
          If you choose to correspond with us through email, we may retain the
          content of your email messages together with your email address and
          our responses.
        </p>
        <p class="font-weight-bolder mb-0">Web Site Use Information</p>
        <p>
          Similar to other commercial Web sites, our Web site utilizes a
          standard technology called “cookies” (see explanation below, “What Are
          Cookies?”) and Web server logs to collect information about how our
          Web site is used. Information gathered through cookies and Web server
          logs may include the date and time of visits, the pages viewed, time
          spent at our Web site, and the Web sites visited just before and just
          after our Web site. We, our advertisers and ad serving companies may
          also use small pieces of code called “web beacons” or “clear gifs” to
          determine which advertisements and promotions users have seen and how
          users responded to them.
        </p>
        <p class="font-weight-bolder mb-0">
          How Do We Use the Information That You Provide to Us?
        </p>
        <p>
          Broadly speaking, we use personal information for purposes of
          administering and expanding our business activities, providing
          customer service and making available other products and services to
          our customers and prospective customers. Occasionally, we may also use
          the information we collect to notify you about important changes to
          our Web site, new services and special offers we think you will find
          valuable.
        </p>
        <p class="font-weight-bolder mb-0">What Are Cookies?</p>
        <p>
          A cookie is a very small text document, which often includes an
          anonymous unique identifier. When you visit a Web site, that site’s
          computer asks your computer for permission to store this file in a
          part of your hard drive specifically designated for cookies. Each Web
          site can send its own cookie to your browser if your browser’s
          preferences allow it, but (to protect your privacy) your browser only
          permits a Web site to access the cookies it has already sent to you,
          not the cookies sent to you by other sites.
        </p>
        <p class="font-weight-bolder mb-0">
          How Do We Use Information We Collect from Cookies?
        </p>
        <p>
          As you shop on our Web site, the site uses its cookies to
          differentiate you from other shoppers so your shopping cart is only
          available to you. In some cases, we also use cookies to prevent you
          from seeing unnecessary advertisements or requiring you to log in more
          than is necessary for security. Cookies, in conjunction with our Web
          server’s log files, allow us to calculate the aggregate number of
          people visiting our Web site and which parts of the site are most
          popular. This helps us gather feedback in order to constantly improve
          our Web site and better serve our customers. Cookies do not allow us
          to gather any personal information about you and we do not generally
          store any personal information that you provided to us in your
          cookies.
        </p>
        <p class="font-weight-bolder mb-0">Sharing Info with Third Parties</p>
        <p>
          Unless you specifically subscribe and agree, we will not share any of
          your information with third parties for marketing purposes of any
          type, period.
        </p>
        <p class="font-weight-bolder mb-0">
          Notice of New Services and Changes
        </p>
        <p>
          Occasionally, we may also use the information we collect to notify you
          about important changes to our Web site, new services and special
          offers we think you will find valuable.
        </p>
        <p class="font-weight-bolder mb-0">
          How Do We Secure Information Transmissions?
        </p>
        <p>
          All of your information, if any, is stored on our secure servers with
          the industry standard SSL certificate to encrypt your data and protect
          it. Email correspondence that goes back and forth from us to you is
          not secure unless both parties have a security certificate when they
          send the email. Our company does not transmit emails with a security
          certificate since our business model does not transmit sensitive
          information through email because we secure all sensitive information,
          including any financial data you may or may not provide, on our secure
          servers.
        </p>
        <p class="font-weight-bolder mb-0">Certain Disclosures</p>
        <p>
          We may disclose your personal information if required to do so by law
          or subpoena or if we believe that such action is necessary to (a)
          conform to the law or comply with legal process served on us or
          Affiliated Parties; (b) protect and defend our rights and property,
          the Site, the users of the Site, and/or our Affiliated Parties; (c)
          act under circumstances to protect the safety of users of the Site,
          ourselves, or third parties.
        </p>
        <p class="font-weight-bolder mb-0">
          What About Other Web Sites Linked to Our Web Site?
        </p>
        <p>
          We are not responsible for the practices employed by Web sites linked
          to or from our Web site nor the information or content contained
          therein. Often links to other Web sites are provided solely as
          pointers to information on topics that may be useful to the users of
          our Web site.
        </p>
        <p>
          Please remember that when you use a link to go from our Web site to
          another Web site, our Privacy Policy is no longer in effect. You’re
          browsing and interaction on any other Web site, including Web sites
          which have a link on our Web site, is subject to that Web site’s own
          rules and policies. Please read over those rules and policies before
          proceeding.
        </p>
        <p class="font-weight-bolder mb-0">Your Consent</p>
        <p>
          By using our Web site you consent to our collection and use of your
          personal information as described in this Privacy Policy. If we change
          our privacy policies and procedures, we will post those changes on our
          Web site to keep you aware of what information we collect, how we use
          it and under what circumstances we may disclose it.
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPage',
  metaInfo() {
    return {
      title: 'Privacy Policy',
      link: [
        {
          rel: 'canonical',
          href: 'https://www.smartcreditaffiliates.com/privacy-policy',
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>

<style lang="scss" scoped>
p {
  font-size: 15px;
}
</style>
