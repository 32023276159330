<template>
  <div>
    <Header />
    <main>
      <transition
        tag="div"
        name="fade"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <router-view></router-view>
      </transition>
    </main>
    <Footer />
  </div>
</template>

<script>
import { Header, Footer } from '@/components/layout'

export default {
  name: 'MainLayout',
  components: {
    Header,
    Footer,
  },
  metaInfo() {
    return {
      title: process.env.VUE_APP_SITE_TITLE,
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'description',
          name: 'description',
          content: process.env.VUE_APP_SITE_DESCRIPTION,
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          vmid: 'og:locale',
          property: 'og:locale',
          content: 'en_us',
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: process.env.VUE_APP_SITE_TITLE,
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: '',
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: process.env.VUE_APP_SITE_DESCRIPTION,
        },
        {
          vmid: 'twitter:card',
          property: 'twitter:card',
          content: 'summary',
        },
        {
          vmid: 'twitter:site',
          property: 'twitter:site',
          content: '',
        },
        {
          vmid: 'twitter:title',
          property: 'twitter:title',
          content: process.env.VUE_APP_SITE_TITLE,
        },
        {
          vmid: 'twitter:description',
          property: 'twitter:description',
          content: process.env.VUE_APP_SITE_DESCRIPTION,
        },
        {
          vmid: 'twitter:image',
          property: 'twitter:image',
          content: '',
        },
        {
          vmid: 'twitter:image:src',
          property: 'twitter:image:src',
          content: '',
        },
        {
          vmid: 'layout',
          property: 'layout',
          content: 'page',
        },
      ],
    }
  },
  data() {
    return {
      prevHeight: 0,
    }
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height
    },
    enter(element) {
      element.parentNode.style.height = this.prevHeight
    },
    afterEnter(element) {
      element.parentNode.style.height = 'auto'
    },
  },
}
</script>
