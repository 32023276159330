<template>
  <header class="bg-white">
    <b-navbar toggleable="md" class="bg-white shadow" fixed="top">
      <div class="w-75">
        <b-navbar-brand :to="{ name: 'HomePage' }"
          ><site-logo
        /></b-navbar-brand>
      </div>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item :to="{ name: 'SignupPage' }">Sign Up</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import SiteLogo from '@/components/common/SiteLogo'

export default {
  name: 'Header',
  components: {
    SiteLogo,
  },
}
</script>
